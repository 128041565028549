import slick from 'slick-carousel';

let $specialistsSlider = $('.specialists__list');
let $stocksInfoSlider = $('.stocks__info-list');
let $stocksImageSlider = $('.stocks__images-list');
let $stocksMobileSlider = $('.stocks__mobile-list');
let $gallerySlider = $('.about__gallery-list');
let $galleryMobileSlider = $('.about__gallery-mobile-list');


if ($specialistsSlider.length > 0) {
    $specialistsSlider.slick({
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        draggable: false,
        swipe: false,
        centerMode: true,
        centerPadding: '140px',
        responsive: [
            {
                breakpoint:  1800,
                settings: {
                    centerMode:true,
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    centerMode: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 378,
                settings: {
                    centerMode: true,
                    centerPadding: '50px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    draggable: false,
                    swipe: false
                }
            }
        ]
    });
    //Активируем функционал стрелок
    //В параметры функции передаем блок стрелок через дата атрибут, название дата атрибута, элемент сладера
    activateArrows($('[data-slider-arrow-specialists]'), 'slider-arrow-specialists', $specialistsSlider);
}

if ($stocksInfoSlider.length > 0) {
    $stocksInfoSlider.slick({
        arrows: false,
        swipe: false
    });

    activateArrows($('[data-slider-arrow-stocks]'), 'slider-arrow-stocks', $stocksInfoSlider);
}

if ($stocksImageSlider.length > 0) {
    $stocksImageSlider.slick({
        arrows:false,
        swipe: false
    });

    activateArrows($('[data-slider-arrow-stocks]'), 'slider-arrow-stocks', $stocksImageSlider);
}

if ($stocksMobileSlider.length > 0) {

    $stocksMobileSlider.slick({
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    activateArrows($('[data-slider-arrow-stocks]'), 'slider-arrow-stocks', $stocksMobileSlider);
}

if ($gallerySlider.length > 0) {
    $gallerySlider.slick({
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '400px',
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '270px',
                }
            }
        ]
    });

    activateArrows($('[data-slider-arrow-gallery]'), 'slider-arrow-gallery', $gallerySlider)
}

if ($galleryMobileSlider.length > 0) {
    $galleryMobileSlider.slick({
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    })

    activateArrows($('[data-slider-arrow-gallery]'), 'slider-arrow-gallery', $galleryMobileSlider)
}



function activateArrows ($elem, $data, slider ) {
    $elem.on('click', function(e) {
        e.preventDefault();
        if ($(this).data($data) === 'left') {
            slider.slick('slickPrev');
        } else if ($(this).data($data) === 'right') {
            slider.slick('slickNext');
        }
    })
}