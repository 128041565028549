require('../scss/app.scss');
require('../fonts/FutureBookC/css/stylesheet.css');

window.noZensmooth = true;

require('../components/smart-tabs/smart-tabs');

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');

require('./field/phone.js');

require('./loader.js');
require('./slider.js');
require('./services.js');
require('./menu.js');
require('./map.js');
require('./parallax.js');
